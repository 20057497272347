<template lang="pug">
div(v-if="loaded")

  ListSchedule(
    :items="productionInstances"
    @select="select"
  )
    template(slot="title") schedule
    template(slot="line1" slot-scope="{ item }") 
      v-layout(row)
        v-flex(xs8) {{item.dashboard.designName}} - {{item.dashboard.jobSubtitle}} - 
          span.font-weight-bold {{item.location.template.name}}
        v-flex(xs4)
          span.font-weight-bold prod date: 
          span {{formatDate(item.dates.schedule)}}
    template(slot="line2" slot-scope="{ item }") 
      v-layout(row)
        v-flex order: {{item.dashboard.orderDisplayName}} / job#: {{item.dashboard.jobNumber}}
</template>

<script>
import moment from 'moment'
import ListSchedule from './list'

export default {
  components: { ListSchedule },
  computed: {
    productionInstances () {
      return this.$store.getters['productionInstances/items']
    },
    loaded () {
      return this.$store.state.productionInstances.loaded
    },
  },
  methods: {
      select (item) {
        // bring up a PI detail
        this.$router.push({path: '/dashboards/inks/' + item._id})
      },
      formatDate (date) {
        if (!date) { return '' }
        return moment(date).format('L')
      },

      
    },
    async created () {
      await this.$store.dispatch('dashboards/_LOAD_INKS')
    }
}
</script>
