<template lang="pug">
v-container(fluid)
  FwdHeadingLarge inks to mix

  v-layout(row)
    v-flex
      ProductionSchedule
</template>

<script>
import ProductionSchedule from './list'

export default {
  components: {
    ProductionSchedule,
  },
  data: () => ({

  }),
  methods: {

  },
  async created () {

  }
}
</script>
